.screen {
	position: absolute !important;
	bottom: 150px;
	right: 160px;
	z-index: 50;
	height: 32px;
	width: 32px;
	background-color: white;
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 2px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 28px;
		height: 28px;
	}
}

.download {
	// position: absolute;
	// bottom: 150px;
	// right: 203px;
	margin-right: 5px;
	height: 32px;
	width: 220px;
	background-color: white;
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 2px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;

	img {
		width: 28px;
		height: 28px;
	}
}