.zooms {
    position: absolute;
    bottom: 40px;
    right: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .5);
    z-index: 50;
}

.zoomIn {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 0 0 4px 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-size: 20px;
    border-bottom: 1px solid #eee;
    border-radius: 4px 4px 0 0;
    color: rgb(0, 0, 0);
}

.zoomIn:hover,
.zoomOut:hover {
    font-weight: bolder;
    background-color: #f0f0f0;
}

.zoomOut {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 0 0 4px 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-size: 20px;
    color: rgb(0, 0, 0);
}