.add {
	height: calc(100vh - 56px);
	width: 400px;
	box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0003;

	.header {
		font-size: 24px;
		line-height: 28px;
		background-color: var(--gray-background);
		border-bottom: 1px solid var(--gray-border);

		.title {
			font-weight: 400;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 16px;
		}
	}

	.body {
		padding: 30px;
		height: calc(100vh - 200px);
		overflow-x: auto;
		overflow-y: auto;
	}

	.footer {
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
		letter-spacing: 0.4px;
		color: var(--gray-text);
		padding: 15px;
		border-top: 1px solid var(--gray-border);
		display: flex;
		justify-content: space-between;
	}
}

.info {
	width: 600px;

	.text {
		padding: 20px 0 10px 0;
		font-size: 14px;
		line-height: 20px;
		color: var(--gray-text);
	}

	.source {
		margin-right: 4px;
		font-size: 12px;
	}

	.button {
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;
	}
}

.loading {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}