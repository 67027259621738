.actions {
	margin-bottom: 20px;
}

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.title {
	font-size: 16px;
	color: #000;
	font-weight: 500;
}