.multipleAgenda {
    padding: 16px;
    border-bottom: 1px solid var(--gray-border)
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    font-weight: 500;
    color: #000000de;
  }
  
  .subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    color: #000000de;
  }
  
  .legend {
    display: flex;
    flex-direction: column-reverse;
  
    div {
      font-weight: 500;
      font-size: 12px;
      height: 16px;
      color: #0009;
    }
  
    span {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  
  .icon {
    width: 16px;
    height: 16px;
    color: #0009;
    cursor: pointer;
    font-size: 16px;
  }