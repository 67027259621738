.importMenuBody {
  padding: 0px;
  border-radius: 4px;
  overflow: hidden;
  overflow: hidden;
  width: 350px;
  position: relative;
  display: flex;
  flex-direction: column;

  .importMenuInfo {
    padding: 20px 16px;

    h6 {
      margin: 0 0 24px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: .25px;
    }

    p {
      margin: 0 0 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: .25px;

    }

    .importRadioLabel>div:nth-child(1) {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: .25px;
    }

    .importRadioLabel>div:nth-child(2) {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #0009;
      letter-spacing: .25px;
    }
  }
}