.container {
  border-radius: 4px !important;
  padding: 0 !important;
  min-width: fit-content;
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024,
    0 9px 46px 8px #0000001f;

  .header {
    font-weight: 500;
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    background-image: linear-gradient(180deg, #909090 0%, #6B6B6B 100%);
    line-height: 60px;
    color: #fff;
    font-size: 24px;
    text-align: left;
  }

  .children {
    padding: 1.5rem;
  }
}