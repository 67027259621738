.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.addButton {
    margin: 20px 0;
    cursor: pointer;
}

.title {
    font-size: 16px;
    color: #000;
    font-weight: 500;
}

.table {
    width: 100%;
    max-height: calc(100% - 300px);
    overflow: auto;
    border-collapse: collapse;
}

th,
td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: #f2f2f2;
}

.status {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.info {
    width: 600px;

    .text {
        padding: 20px 0 10px 0;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray-text);
    }
}

.row {
    height: 40px;
    .delete {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        display: none;
    }
}

.row:hover {
    background-color: var(--gray-hover);
    position: relative;
    cursor: pointer;

    .delete {
        display: inline-block;
    }

    .icons {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        background: linear-gradient(90deg, rgba(238, 238, 238, 0) 0, #f5f5f5 24px);
        padding: 4px 10px 3px 15px;
        border-radius: 4px;
        width: 110px;
        right: 0px;
        top: 0;
        bottom: 0;
        z-index: 50;
        color: var(--gray-text);
    }

    .icons:hover {
        color: #000;
    }
}