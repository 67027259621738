.error {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F0F0F0;

    .content {
        background-color: #E7E7E7;
        padding: 20px 30px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 30vw;
        height: 12vw;
    }
}