.agendasM {
  position: absolute;
  left: 420px;
  bottom: 40px;
  height: fit-content;
  width: 240px;
  padding-bottom: -40px;
  z-index: 51;
  background-color: white;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0003;
  border-radius: 4px;
  max-height: 60vh;
  overflow: auto;
}

.agendasS {
  @extend .agendasM;
  left: 20px;
}

.agendasL {
  @extend .agendasM;
  left: 820px;
}