.section {
	margin-bottom: 10px;
	border-bottom: 1px solid #e8e8e8;
	padding-bottom: 10px;
	&:last-child {
		border-bottom: none;
	}
}

.loading {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.collapse{
    margin-left: 10px;
    margin-right: 10px;
}