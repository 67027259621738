.container {
	flex: 1;
	height: calc(100% - 70px);
	display: flex;
	flex-direction: column;
}

.title {
	font-size: 16px;
	color: #000;
	font-weight: 500;
}

.loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
