.home {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex: 1;
    height: calc(100vh - 56px);
    width: calc(100% - 88px);
}

.arrowLeftIcon {
    position: absolute;
    background-color: #fff;
    top: 50%;
    transform: translateY(-50%);
    left: -1px;
    width: 24px;
    height: 48px;
    border-radius: 0 4px 4px 0;
    box-shadow: 0 2px 4px #0003, 0 1px 10px #0000001f, 0 4px 5px #00000024;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
        height: 24px;
        width: 24px;
        color: #0009;
        zoom: 1.2;
    }
}

.arrowRightIcon {
    position: absolute;
    background-color: #fff;
    top: 50%;
    transform: translateY(-50%);
    right: -1px;
    width: 24px;
    height: 48px;
    border-radius: 4px 0px 0px 4px;
    box-shadow: 0 2px 4px #0003, 0 1px 10px #0000001f, 0 4px 5px #00000024;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
        height: 24px;
        width: 24px;
        color: #0009;
        zoom: 1.2;
    }
}